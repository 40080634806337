import React, {Fragment, useState} from "react";
import PropTypes           from "prop-types";
import {Spinner}           from "../dom/Spinner";
import {DefaultDatepicker} from "../dom/DefaultDatepicker";
import {ErrorContainer}    from "../dom/ErrorContainer";
import {Select}            from "../dom/Select";
import {Helpers}           from "../helpers/Helpers";

const axios = require("axios").default;

const Input = prop => {
    return (
        <input className={prop.className}
               type={prop.type}
               id={prop.id}
               name={prop.name}
               onChange={prop.onChange}
               value={prop.value}/>
    );
};

export const PaymentTableRow = props => {
    const [errors, setErrors] = useState([]);
    const [isLoaded, setLoaded] = useState(true);
    const [isEditing, setEditing] = useState(false);
    const [payment, setPayment] = useState(props.payment);

    const changeField = (event) => {
        event.preventDefault();
        setEditing(true);
    };

    const cancelEditField = (event) => {
        event.preventDefault();
        setEditing(false);
    };

    const saveField = (event) => {
        event.preventDefault();
        setLoaded(false);
        axios.put('/api/payments/',
            {
                "element": payment,
            },
        )
             .then((response) => {
                 props.onPaymentChange(true);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, "Не удалось выполнить запрос на запись!"]);
                 setLoaded(true);
             });
    };

    const deleteField = async (event) => {
        event.preventDefault();
        const confirmRes = await diary.getConfirm("Действительно удалить оплату без возможности восстановления?");
        if (!confirmRes) {
            return false;
        }
        setLoaded(false);
        axios.delete(`/api/payments/${payment.ID}/`)
             .then((response) => {
                 if (typeof props.onPaymentChange === 'function') {
                     props.onPaymentChange();
                 }
                 setLoaded(true);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, "Не удалось удалить запись!"]);
                 setLoaded(true);
             });
    };

    const handleChange = (e) => {
        const {id, value} = e.target;
        setPayment({...payment, [id]: value});
    };

    const handleDateChange = (date) => {
        if (date) {
            setPayment({
                ...payment, ...{
                    "ts": Helpers.getTsInSeconds(date),
                    "date": Helpers.getLocalISOString(date),
                    "date_display": date.toLocaleString(),
                },
            });
        }
    };

    const BaseButtonSet = props => (
        <Fragment>
            <a href={""} className="text-success me-3" onClick={changeField}>
                <i className="fas fa-pencil-alt"></i></a>
            <a href={""} className="text-danger" onClick={deleteField}>
                <i className="fas fa-trash"></i></a>
        </Fragment>
    );

    const EditButtonSet = props => (
        <Fragment>
            <a href={""} className="text-success me-3" onClick={saveField}>
                <i className="fas fa-check"/></a>
            <a href={""} className="text-danger" onClick={cancelEditField}>
                <i className="fas fa-times"/></a>
        </Fragment>
    );

    const ButtonSet = prop => {
        if (props.editMode) {
            if (isEditing) {
                return <EditButtonSet/>;
            } else {
                return <BaseButtonSet/>;
            }
        }
        return ("");
    };

    const DateCellSet = prop => {
        if (props.editMode) {
            if (isEditing) {
                return (
                    <DefaultDatepicker
                        id={"date"}
                        name={"date"}
                        dateFormat={"dd.MM.yyyy"}
                        selected={payment.date}
                        onChange={date => handleDateChange(date)}
                    />
                );
            }
        }
        return (
            <span className="display_data">{payment.display_date}</span>
        );
    };

    const PaymentsMethodsSelect = prop => {
        if (props.editMode) {
            if (isEditing) {
                return (
                    <Select
                        id={"p_method"}
                        name={"p_method"}
                        onChange={handleChange}
                        options={props.paymentsMethods}
                        selected={payment.p_method}/>
                );
            }
        }
        return (
            <span className="display_data">{payment.display_p_method || "не установлен"}</span>
        );
    };

    return (
        <Fragment>
            {errors.length > 0 ?
                <tr>
                    <td className={"bg-transparent"} colSpan={7}>
                        <ErrorContainer arErrors={errors}/>
                    </td>
                </tr>
                :
                <>
                    {!isLoaded ?
                        <tr>
                            <td colSpan={7}>
                                <Spinner/>
                            </td>
                        </tr>
                        : (
                            <tr>
                                <td className="py-2">
                                    {payment.ID}
                                </td>
                                <td className="py-2">
                                    {props.editMode && isEditing ?
                                        <Input className={"form-control"}
                                               id={"dID"}
                                               name={"dID"}
                                               type={"text"}
                                               value={payment.dID}
                                               onChange={handleChange}/>
                                        : (
                                            <span>{payment.dID}</span>
                                        )}
                                </td>
                                <td className="py-2">
                                    <DateCellSet/>
                                </td>
                                <td className="text-end py-2">
                                    {props.editMode && isEditing ?
                                        <Input className={"form-control"}
                                               id={"deb"}
                                               name={"deb"}
                                               type={"number"}
                                               value={payment.deb}
                                               onChange={handleChange}/>
                                        : (
                                            <span>{payment.display_deb}</span>
                                        )}
                                </td>
                                <td className="text-center py-2">
                                    <PaymentsMethodsSelect/>
                                </td>
                                <td className="text-center py-2">
                                    {payment.user}
                                </td>
                                <td className="text-end py-2">
                                    <ButtonSet/>
                                </td>

                            </tr>

                        )}
                </>
            }
        </Fragment>
    );
};

PaymentTableRow.propTypes = {
    payment: PropTypes.object,
    paymentsMethods: PropTypes.array,
    onPaymentChange: PropTypes.func,
};
