import React, {useEffect, useState} from "react";
import PropTypes        from "prop-types";
import {PaymentAddForm} from "./PaymentAddForm";
import {PaymentsTable}  from "./PaymentsTable";
import axios            from "axios";
import {ErrorContainer} from "../dom/ErrorContainer";

export const StudentPaymentsSection = props => {

    const [needReload, setNeedReload] = useState(true);
    const [errors, setErrors] = useState([]);
    const [paymentsMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        getPaymentsMethods();
    }, []);

    const getPaymentsMethods = () => {
        axios.get('/api/payments_methods/', {params: {sortSort: 'asc'}})
             .then((response) => {
                 setPaymentMethods(response.data);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
             });
    }

    const onPaymentChange = (needReload) => {
        setNeedReload(needReload);
    };

    return (
        <div className="mb-5">
            <h5>Оформить оплату</h5>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}
            <PaymentAddForm
                paymentData={props.paymentData}
                paymentsMethods = {paymentsMethods}
                backUrl={props.backUrl}
                onPaymentChange={onPaymentChange}
            />
            <h5>Оплаты слушателя по выбранному Договору</h5>
            <PaymentsTable
                needReload={needReload}
                paymentsMethods = {paymentsMethods}
                onPaymentChange={onPaymentChange}
                contractId={props.paymentData.dID}
            />
        </div>
    );

};

StudentPaymentsSection.propTypes = {
    payment: PropTypes.object,
    backUrl: PropTypes.string,
};
