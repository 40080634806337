import React, {Fragment, useEffect, useState} from "react";
import {Spinner}                              from "../dom/Spinner";
import PropTypes                              from "prop-types";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {PaymentTableRow}                      from "./PaymentTableRow";

const axios = require("axios").default;

export const PaymentsTable = props => {

    const [errors, setErrors] = useState([]);
    const [paymentsList, setPaymentsList] = useState([]);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        loadData();
    }, [props.needReload]);

    useEffect(() => {
        if (!paymentsList.list && isLoaded) {
            setErrors(errors => [...errors, "Не обнаружены оплаты, соответствующие запросу!"]);
        }
    }, [paymentsList]);

    const loadData = () => {
        setLoaded(false);
        axios.get(`/api/contracts/${props.contractId}/payments/`)
             .then((response) => {
                 setLoaded(true);
                 setErrors([]);
                 setPaymentsList(response.data);
                 props.onPaymentChange(false);
             })
             .catch((error) => {
                 setLoaded(true);
                 setErrors(errors => [...errors, error]);
                 props.onPaymentChange(false);
             });
    };

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> :
                <>
                    {!isLoaded ? <Spinner/> : (
                        <table className="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>ID договора</th>
                                <th>Дата</th>
                                <th className="text-end">Сумма</th>
                                <th className="text-center">Метод оплаты</th>
                                <th className="text-center">Оператор ID</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {paymentsList.list ? paymentsList.list.map((payment, index) => (
                                    <PaymentTableRow
                                        key={index}
                                        payment={payment}
                                        paymentsMethods={props.paymentsMethods}
                                        editMode={true}
                                        onPaymentChange={props.onPaymentChange}/>
                                ),
                                ) :
                                <tr>
                                    <td colSpan={6}>Не удалось получить платежи!</td>
                                </tr>
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan="2" className="fw-bold py-3 text-end">Итого:</td>
                                <td className="fw-bold py-3 text-end">{paymentsList.display_total}</td>
                                <td colSpan={3}></td>
                            </tr>
                            </tfoot>
                        </table>
                    )}
                </>
            }
        </Fragment>
    );

};

PaymentsTable.propTypes = {
    onPaymentChange: PropTypes.func,
    needReload: PropTypes.bool,
    contractId: PropTypes.string,
    paymentsMethods: PropTypes.array,
};
