import React, {useState}            from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru                           from "date-fns/locale/ru";
import PropTypes                    from "prop-types";
import {Helpers}                    from "../helpers/Helpers";

registerLocale("ru", ru);

export const DefaultDatepicker = props => {

    const [date, setDate] = useState(Helpers.getDateFromString(props.selected));
    const defaultDate = new Date;

    const handleDateChange = (date) => {
        setDate(date);
        props.onChange(date);
    };

    let defaultProps = {

    }

    return (
        <DatePicker
            className="form-control"
            autoComplete={"Off"}
            id={props.id || props.name}
            name={props.name}
            dateFormat={props.dateFormat}
            locale="ru"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            required={props.required}
            maxDate={props.maxDate || ""}
            dropdownMode="select"
            placeholderText="Кликните для выбора даты!"
            openToDate={date ? date : defaultDate}
            selected={date ? date : ""}
            onBlur={props.onBlur}
            onChange={date => handleDateChange(date)}
            popperModifiers={{
                preventOverflow: {
                    enabled: true
                },
            }}
        />
    );
};

DefaultDatepicker.propTypes = {
    name: PropTypes.string,
    selected: PropTypes.string,
    maxDate: PropTypes.string,
    dateFormat: PropTypes.string,
    onChange: PropTypes.func,
};
