import {Pages}                  from "./Pages";
import ReactDOM                 from "react-dom";
import React                    from "react";
import {StudentPaymentsSection} from "../components/StudentPaymentsSection";

export class ContractsPayments extends Pages {

    constructor(params) {
        super(params);

        this.jsonData = window.diaryJsData;
        const container = document.getElementById('contractsPaymentsSection');

        ReactDOM.render(
            <StudentPaymentsSection
                paymentData = {this.jsonData.paymentData}
                backUrl = {this.jsonData.backUrl}
            />,
            container
        );

    }
}
