import React, {Fragment, useEffect, useState} from "react";
import PropTypes           from "prop-types";
import {Spinner}           from "../dom/Spinner";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import {DefaultDatepicker} from "../dom/DefaultDatepicker";
import {Utils}             from "../Utils";
import {ErrorContainer}    from "../dom/ErrorContainer";
import {Select}            from "../dom/Select";
import {Helpers}           from "../helpers/Helpers";

const axios = require("axios").default;

export const PaymentAddForm = props => {
    const [errors, setErrors] = useState([]);
    const [isLoaded, setLoaded] = useState(true);
    const [payment, setPayment] = useState(props.paymentData);
    const formId = "payment_add_form";
    const date = new Date();
    const paymentsDefault = {
        "deb": 0,
        "ts": Math.ceil(date.getTime() / 1000),
        "date": date.toISOString(),
        "user": app.session.ID,
    };

    useEffect(() => {
        setValidation();
        setDefaultPayment();
    }, []);

    const savePayment = (event) => {
        event.preventDefault();
        let $form = $("#" + formId);

        if (!$form.valid()) {
            return false;
        }
        setLoaded(false);
        axios.put('/api/payments/',
            {
                "element": {
                    "ID": payment.ID,
                    "sID": payment.sID,
                    "dID": payment.dID,
                    "gID": payment.gID,
                    "deb": payment.deb,
                    "cred": 0,
                    "ts": payment.ts,
                    "date": payment.date,
                    "user": payment.user,
                    "p_method": payment.p_method,
                    "edited": null,
                },
            },
        )
             .then((response) => {
                 setDefaultPayment();
                 setLoaded(true);
                 props.onPaymentChange(true);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, "Не удалось выполнить запрос!"]);
                 setLoaded(true);
                 props.onPaymentChange(false);
             });
    };

    const setValidation = () => {
        $("#" + formId).validate(
            {
                rules: {
                    "p_method": {
                        required: true,
                    },
                    "date": {
                        required: true,
                    },
                    "deb": {
                        required: true,
                        number: true,
                    },
                },
            },
        );
    };

    const setDefaultPayment = () => {
        setPayment({...payment, ...paymentsDefault});
    };

    const cancelButtonClick = (event) => {
        event.preventDefault();
        if (props.backUrl) {
            window.location.href = props.backUrl;
        }
    };

    const handleChange = (event) => {
        let {id, value} = event.target;
        setPayment({...payment, [id]: value});
    };

    const setDate = (date) => {
        if (date) {
            setPayment({
                ...payment, ...{
                    "ts": Helpers.getTsInSeconds(date),
                    "date": Helpers.getLocalISOString(date),
                    "date_display": date.toLocaleString(),
                },
            });
        }
    };

    const handleDebChange = (e) => {
        let value = e.target.value;
        if (!Utils.isInteger(value)) {
            value = payment.deb;
        }
        setPayment({...payment, deb: value});
    };

    return (
        <Fragment>
            {!isLoaded ?
                <div className={"py-5"}>
                    <Spinner/>
                </div>
                : (
                    <form id={formId} method={"POST"}>

                        {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

                        <div className="row">
                            <div className="form-group col">
                                <Select
                                    id={"p_method"}
                                    name={"p_method"}
                                    label={"Способ оплаты"}
                                    onChange={handleChange}
                                    defaultOption={"Выбрать метод оплаты"}
                                    options={props.paymentsMethods}
                                    selected={payment.p_method}/>
                            </div>
                            <div className="form-group col-auto">
                                <label className={"d-block"} htmlFor="date">Дата платежа</label>
                                <DefaultDatepicker
                                    name={"date_header"}
                                    selected={payment.date || ""}
                                    dateFormat={"dd.MM.yyyy"}
                                    onChange={date => setDate(date)}
                                />

                            </div>
                            <div className="form-group col">
                                <label htmlFor="deb">Сумма</label>
                                <input className="form-control"
                                       key="top_deb"
                                       id="deb"
                                       name="deb"
                                       type="text"
                                       onChange={handleDebChange}
                                       value={payment.deb || ""} autoComplete="off"/>
                            </div>
                        </div>

                        <div className="text-end">
                            <button className="btn btn-primary me-3" type={"submit"} onClick={savePayment}>Сохранить
                            </button>
                            {!props.backUrl ?
                                <button className="btn btn-secondary" onClick={cancelButtonClick}>закрыть</button>
                                : ("")}
                        </div>

                    </form>
                )}
        </Fragment>
    );
};

PaymentAddForm.propTypes = {
    backUrl: PropTypes.string,
    payment: PropTypes.object,
    paymentsMethods: PropTypes.array,
    onPaymentChange: PropTypes.func,
};
